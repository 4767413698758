import React, { Component } from "react";
import { connect } from "react-redux";
import { FiPlus, FiUsers } from "react-icons/fi";
import Layout from "components/Layout";
import GroupList from "components/Groups";
import GroupEdit from "components/Groups/GroupEditModal";
import Loading from "components/Loading";
import Modal from "components/Modal";
import { showModal, hideModal } from "actions/modalActions";
import * as groupsActions from "actions/groupsActions";
import * as calendarsActions from "actions/calendarsActions";

class Groups extends Component {
	constructor(props) {
		super(props);
		// console.log(props);

		this.closeModal = this.closeModal.bind(this);
		this.openNewGroupModal = this.openNewGroupModal.bind(this);
		this.getGroups = this.getGroups.bind(this);
	}

	componentDidMount() {
		this.props.getSelectableCalendars();
		this.getGroups();
	}

	async getGroups() {
		this.props.getChildGroups(); // desplegable is_multi = false
		await this.props.getAllGroups({ sort: "+name", variant: "detailed" });
	}

	closeModal() {
		this.props.hideModal();
	}

	openNewGroupModal() {
		this.props.showModal(
			{
				open: true,
				title: "Nuevo grupo",
				style: { width: "400px" },
				content: <GroupEdit getGroups={this.getGroups} handleClose={this.closeModal} />,
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	getContent = () => {
		// console.log(this.state);
		if (this.props.loading) {
			return <Loading />;
		}

		if (this.props.error) {
			return "No se han podido cargar los grupos";
		}

		if (this.props.detailed_groups.length == 0) {
			return (
				<div className="first-steps">
					<FiUsers className="icono-pasos" />
					<h3 className="title2">Primeros pasos</h3>

					<p>
						Un grupo contiene un <strong>conjunto de empleados</strong> que
						ofrecen un determinado servicio. Aquí te explicamos cómo funcionan:{" "}
						<a
							className="alink"
							target="_blank"
							href="https://help.plain.ninja/es/articles/3693846-que-es-un-grupo-y-para-que-sirve"
						>
							https://help.plain.ninja/es/articles/3693846-que-es-un-grupo-y-para-que-sirve
						</a>
					</p>
				</div>
			);
		}

		return <GroupList />;
	};

	render() {
		return (
			<>
				<Layout className="page-groups" context="groups">
					<div className="heading">
						<h1 className="title">Grupos</h1>
						<button
							type="button"
							title="Crear grupo"
							onClick={this.openNewGroupModal}
							className="btn btn-new"
						>
							<FiPlus />
						</button>
					</div>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.groupsReducer,
		loading: reducers.groupsReducer.loading,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getAllGroups: (options) => dispatch(groupsActions.getAllGroups(options)),
	getChildGroups: () => dispatch(groupsActions.getChildGroups()),
	getSelectableCalendars: () =>
		dispatch(calendarsActions.getSelectableCalendars()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
