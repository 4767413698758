import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { functionsService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import * as functionsActions from 'actions/functionsActions';

class FunctionEdit extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);

    // 'function' is a reserved word
    let func = {
      name: '',
      enabled: true,
    }

    let isEdit = false;

    if (props.function) {
      func = props.function;
      isEdit = true;
    }

    this.state = {
      isEdit,
      isLoading: false,
      submitted: false,
      errors: {},
      func
    };
  }

  handleChange = event => {
    const { func } = this.state;

    this.setState({
      func: {
        ...func,
        [event.target.id]: event.target.value
      },
    });
  }

  handleSwitch = event => {
    const { func } = this.state;

    this.setState({
      func: {
        ...func,
        [event.target.id]: event.target.checked
      },
    });
  }

  checkValidField = (name) => {
    return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let response = '';
    const props = this.props;

    // if edit => update; else => new
    if (this.state.isEdit) {
      response = await functionsService.update(this.state.func);
    }
    else {
      response = await functionsService.add(this.state.func);
    }

    //console.log(response);

    if (response.ok) {
      this.setState({
        isLoading: false,
        errors: {},
      }, () => {

        toastr.success('¡Bien!', 'Cambios guardados correctamente');
        this.props.getFunctions();
        props.handleClose();

      });
    }
    else {
      this.setState({
        isLoading: false,
        submitted: true,
        errors: errorFormating(response)
      });
    }
  }

  render() {
    const { func } = this.state;
    var isActive = func.enabled;

    return (

      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <FormGroup controlId="name">
            <FormLabel>Nombre <span className="label-required">*</span></FormLabel>
            <FormControl
              type="text"
              value={func.name}
              onChange={this.handleChange}
              placeholder="Nombre de la función"
              isInvalid={this.checkValidField('name')}
            />

            <FormControl.Feedback type="invalid">
              {this.state.errors.name}
            </FormControl.Feedback>
          </FormGroup>

          <FormGroup className="form-group-flex mb0" controlId="enabled">
            <FormLabel>Activo</FormLabel>
            <Switch
              controlId="enabled"
              isOn={isActive}
              handleToggle={this.handleSwitch}
            />

            <FormControl.Feedback type="invalid">
              {this.state.errors.enabled}
            </FormControl.Feedback>
          </FormGroup>
        </div>

        <div className="modal-footer">
          <button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
          <SubmitButton
            type="submit"
            isLoading={this.state.isLoading}
            text="Guardar"
            loadingText="Guardando..."
          />
        </div>
      </form>
    )
  }
}


const mapStateToProps = (reducers) => {
  return reducers.functionsReducer;
};


export default connect(mapStateToProps, functionsActions)(FunctionEdit)
