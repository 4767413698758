import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { FiCalendar } from "react-icons/fi";
import { Row, Col, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import Switch from "components/Switch";
import { showModal, hideModal } from "actions/modalActions";
import Avatar from "components/Avatar";
import AvatarEdit from "components/Avatar/AvatarEdit";
import { employeesService, widgetsService } from "services";
import { errorFormating, isSupervisor } from "utils/utils";

class EmployeeInfo extends React.Component {
	constructor(props) {
		super(props);

		const employee = this.props.employee;
		// console.log(employee);

		const role = employee.roles.filter((item) => item !== "SUPERVISOR");
		employee.role = role.toString();

		this.state = {
			employee,
			prevEmployee: employee,
			isMe: this.props.employee.id === this.props.user.id,
			errors: {},
			error_footer: "",
			modal_alert: false
		};

		this.closeModal = this.closeModal.bind(this);
		this.openAvatarModal = this.openAvatarModal.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
	}

	closeModal() {
		this.props.hideModal();
	}

	openAvatarModal() {
		this.props.showModal(
			{
				open: true,
				title: "Editar avatar",
				style: { width: "300px" },
				content: (
					<AvatarEdit
						userId={this.state.employee.id}
						onClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"empty"
		);
	}

	openDeleteModal() {
		this.props.showModal(
			{
				open: true,
				title: "Eliminar empleado",
				style: { width: "400px" },
				message:
					"¿Desea eliminar el empleado? Esta acción no se puede deshacer",
				deleteAction: this.confirmDelete,
				closeModal: this.closeModal,
			},
			"delete"
		);
	}

	handleSwitch = (event) => {
		const { employee } = this.state;

		employee[event.target.id] = event.target.checked;

		if (!employee.enabled)
			employee.with_access = false;

		if (employee.with_access)
			employee.enabled = true;

		this.setState(
			{
				employee,
			},
			() => {
				this._saveEmployee();
			}
		);
	};

	handleChange = (event) => {
		const { employee } = this.state;

		const key = event.target.id;
		const value = event.target.value;

		var modal_alert = false;

		if (key === "end_day") {
			const today = new Date();
			const moment_end_day = moment.utc(value);
			const moment_today = moment.utc(today);
			modal_alert = moment_end_day.isBefore(moment_today);
		}

		this.setState({
			prevEmployee: employee,
			employee: {
				...employee,
				[key]: value,
			},
			modal_alert
		});
	};

	handleWidgetChange = async (event) => {
		const { employee } = this.state;

		const key = event.target.id;
		const value = event.target.value;
		var response;

		if (value > 0) {
			response = await widgetsService.addToEmployee(employee.id, value);
			// console.log(response);

		}
		else {
			response = await widgetsService.deleteFromEmployee(employee.id, employee.widget_template_id);
			// console.log(response);
		}

		if (response.ok) {
			this.setState({
				prevEmployee: employee,
				employee: {
					...employee,
					[key]: value,
				},
			});
			toastr.success("¡Bien!", "Cambios realizados correctamente");
		}
	};

	handleBlur = async () => {
		const { modal_alert } = this.state;
		const hasChanged = this.state.employee !== this.state.prevEmployee;

		if (hasChanged && modal_alert) {
			this.openAlertBajaModal();
		} else if (hasChanged) {
			this._saveEmployee();
		}
	};

	openAlertBajaModal() {
		this.props.showModal(
			{
				open: true,
				title: "Confirmar acción",
				message: "Si indicas una fecha de baja anterior a la fecha actual, se eliminarán las transacciones y planificación a partir de la fecha de baja",
				confirmAction: () => this.confirmChange(),
				closeModal: () => this.undoChange(),
				style: { width: "420px" },
			},
			"confirm"
		);
	}

	confirmChange = () => {
		this.setState({
			modal_alert: false
		});
		this._saveEmployee();
		this.closeModal();
	};

	undoChange = () => {
		const { prevEmployee } = this.state;

		this.setState({
			employee: prevEmployee,
			modal_alert: false
		});
		this.closeModal();
	};

	confirmDelete = async () => {
		const response = await employeesService.remove(this.state.employee.id);
		this.closeModal();

		if (response.status === 200)
			this.props.history.goBack();
		else {
			let textError = errorFormating(response);

			this.setState({
				error_footer: textError.id,
			});
		}
	};

	handleSendInvitation = async () => {
		const response = await employeesService.sendInvitation(
			this.state.employee.id
		);

		if (response.ok) {
			toastr.success("", "Se ha enviado el email correctamente");
		} else {
			toastr.error(
				"",
				"Se ha producido un error y no se ha podido enviar el email :("
			);
		}
	};

	_saveEmployee = async () => {
		const { employee, prevEmployee } = this.state;

		const hasChangedRole = employee.role !== prevEmployee.role;
		// console.log(hasChangedRole);

		if (hasChangedRole) {
			const roles = [];
			roles.push(employee.role);
			if (prevEmployee.roles.includes("SUPERVISOR")) {
				roles.push("SUPERVISOR");
			}
			employee.roles = roles;
		}

		// param role is sent, but only roles is used in backend
		const response = await employeesService.update(employee);

		if (response.ok) {
			toastr.success("¡Bien!", "Cambios realizados correctamente");
		}

		this.setState({
			prevEmployee: employee,
			errors: errorFormating(response),
		});
	};

	checkValidField = (name) => {
		return (
			this.state.errors[name] !== undefined && this.state.errors[name] !== ""
		);
	};

	getSupervisedGroups = () =>
		this.props.employee.groups_supervised.map((group, i) => {
			return <li key={i}>{group.name}</li>;
		});

	getContent = () => {
		const { employee } = this.state;
		const { widget_templates } = this.props;
		var isActive = employee.enabled;
		var hasAccess = employee.with_access;
		let template_options = widget_templates.length > 0 && widget_templates.map((item, i) => {
			return (
				<option key={i} value={item.id}>
					{item.name}
				</option>
			);
		}, this);

		return (
			<>
				<div className="content-heading">
					<Avatar
						onClick={this.openAvatarModal}
						userId={employee.id}
						className="avatar-editable avatar-lg"
						alt={employee.name}
					/>

					{employee.groups_supervised.length > 0 && (
						<div className="form-group no-form form-group-supervised">
							<label>Supervisa</label>
							<ul className="list-supervised">{this.getSupervisedGroups()}</ul>
						</div>
					)}
				</div>
				<Row>
					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="name">
							<FormLabel>
								Nombre <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={employee.name}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Nombre empleado"
								isInvalid={this.checkValidField("name")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.name}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="surname">
							<FormLabel>
								Apellidos <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="text"
								value={employee.surname}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Apellidos empleado"
								isInvalid={this.checkValidField("surname")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.surname}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="email">
							<FormLabel>
								Email <span className="label-required">*</span>
							</FormLabel>
							<FormControl
								type="email"
								value={employee.email}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Email"
								isInvalid={this.checkValidField("email")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.email}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="position">
							<FormLabel>Cargo</FormLabel>
							<FormControl
								type="text"
								value={employee.position}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Cargo empleado"
								required
							/>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup
							controlId="birthday"
							className={`form-date ${employee.birthday === "" && "empty"}`}
						>
							<FormLabel>Fecha de nacimiento</FormLabel>
							<FormControl
								type="date"
								value={employee.birthday}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								isInvalid={this.checkValidField("birthday")}
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								{this.state.errors.birthday}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="phone">
							<FormLabel>Teléfono</FormLabel>
							<FormControl
								type="text"
								value={employee.phone}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="99 123 123 123"
								isInvalid={this.checkValidField("phone")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.phone}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup
							controlId="start_day"
							className={`form-date ${employee.start_day === "" && "empty"}`}
						>
							<FormLabel>Fecha de alta</FormLabel>
							<FormControl
								type="date"
								value={employee.start_day}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								isInvalid={this.checkValidField("start_day")}
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								{this.state.errors.start_day}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup
							controlId="end_day"
							className={`form-date ${employee.end_day === "" && "empty"}`}
						>
							<FormLabel>Fecha de baja</FormLabel>
							<FormControl
								type="date"
								value={employee.end_day}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								isInvalid={this.checkValidField("end_day") || this.checkValidField("contract_id")}
							/>
							<FiCalendar />
							<FormControl.Feedback type="invalid">
								{this.state.errors.end_day} {this.state.errors.contract_id}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="role">
							<FormLabel>Rol</FormLabel>
							<FormControl
								disabled={this.state.isMe}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								value={employee.role}
								as="select"
							>
								<option value="ADMIN">Administrador</option>
								<option value="EMPLOYEE">Empleado</option>
							</FormControl>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="code">
							<FormLabel>Código</FormLabel>
							<FormControl
								type="text"
								value={employee.code}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Código empleado"
								isInvalid={this.checkValidField("code")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.code}
							</FormControl.Feedback>
						</FormGroup>
					</Col>

					<Col sm={6} md={4} lg={3}>
						<FormGroup controlId="card_code">
							<FormLabel>Código de tarjeta</FormLabel>
							<FormControl
								type="text"
								value={employee.card_code}
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								placeholder="Código de tarjeta"
								isInvalid={this.checkValidField("card_code")}
							/>
							<FormControl.Feedback type="invalid">
								{this.state.errors.card_code}
							</FormControl.Feedback>
						</FormGroup>
					</Col>


					{employee.seniority && (
						<Col sm={6} md={4} lg={3}>
							<FormGroup controlId="seniority">
								<FormLabel>Antigüedad</FormLabel>
								<p className="seniority">
									<span className="seniority-time">
										{employee.seniority.years}
									</span>
									{employee.seniority.years === 1 ? "año" : "años"}
									<span className="seniority-time">
										{employee.seniority.months}
									</span>
									{employee.seniority.months === 1 ? "mes" : "meses"}
									&nbsp;y{" "}
									<span className="seniority-time">
										{employee.seniority.days}
									</span>
									{employee.seniority.days === 1 ? "día" : "días"}
								</p>
							</FormGroup>
						</Col>
					)}

					{widget_templates && widget_templates.length > 0 && (
						<Col sm={6} md={4} lg={3}>
							<FormGroup controlId="widget_template_id">
								<FormLabel>Plantilla inicio</FormLabel>
								<FormControl
									onChange={this.handleWidgetChange}
									value={employee.widget_template_id}
									as="select"
								>
									<option value="-1">Defecto</option>
									{template_options}
								</FormControl>
							</FormGroup>
						</Col>
					)}

					<Col sm={6} md={4} lg={4}>
						<FormGroup controlId="time_registration_balance_calculation">
							<FormLabel>Cálculo del balance de fichajes</FormLabel>
							<FormControl
								onChange={this.handleChange}
								onBlur={this.handleBlur}
								value={employee.time_registration_balance_calculation}
								as="select"
							>
								<option value="PLANNED_HOURS">Horas planificadas</option>
								<option value="TARGET_HOURS">Horas objetivo</option>
							</FormControl>
						</FormGroup>
					</Col>

					{!this.state.isMe && (
						<>
							<Col sm={3} md={1} lg={1}>
								<FormGroup controlId="enabled">
									<FormLabel>Activo</FormLabel>
									<Switch
										controlId="enabled"
										isOn={isActive}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>
							</Col>

							<Col sm={3} md={2} lg={2}>
								<FormGroup controlId="with_access">
									<FormLabel className="inline-block">Acceso a Plain</FormLabel>
									<Switch
										controlId="with_access"
										isOn={hasAccess}
										handleToggle={this.handleSwitch}
									/>
								</FormGroup>
							</Col>
						</>
					)}
				</Row>

				{this.state.error_footer && (
					<div
						className="alert alert-danger alert-dismissible fade show mt15"
						role="alert"
					>
						{this.state.error_footer}
					</div>
				)}

				{this.state.errors && this.state.errors.general && (
					<div
						className="alert alert-danger alert-dismissible fade show mt15"
						role="alert"
					>
						{this.state.errors.general}
					</div>
				)}

				{!this.state.isMe && (
					<div className="row-buttons">
						<button
							type="button"
							className="btn btn-danger"
							onClick={this.openDeleteModal}
						>
							Eliminar empleado
						</button>

						{hasAccess && (
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={this.handleSendInvitation}
							>
								Reenviar email acceso a Plain
							</button>
						)}
					</div>
				)}
			</>
		);
	};

	render() {
		return <>{this.getContent()}</>;
	}
}

const mapStateToProps = (state) => {
	return {
		...state.dashboardReducer,
		user: state.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
});

// export default connect(mapStateToProps, mapDispatchToProps)(EmployeeInfo);
export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(EmployeeInfo);
