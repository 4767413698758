import React from "react";
import { toastr } from "react-redux-toastr";
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiArrowLeft, FiCalendar } from "react-icons/fi";
import moment from "moment";
import Layout from "components/Layout";
import SubmitButton from "components/SubmitButton";
import Switch from "components/Switch";
import { authService, employeesService } from "services";
import { errorFormating } from "utils/utils";

const today = moment().format("YYYY-MM-DD");

class EmployeeNew extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			submitted: false,
			errors: [],
			user: {
				name: "",
				surname: "",
				company: authService.currentUser.company.id,
				email: "",
				start_day: today,
				// end_day: "",
				enabled: false,
				with_access: false,
				birthday: "", // TODO ""
				phone: "",
				code: "",
				role: "EMPLOYEE",
				roles: [],
				position: "",
			},
		};
	}

	handleBackClick = () => {
		this.props.history.goBack();
	};

	handleChange = (event) => {
		const { user } = this.state;
		this.setState({
			user: {
				...user,
				[event.target.id]: event.target.value,
			},
		});
	};

	handleSwitch = (event) => {
		const { user } = this.state;

		user[event.target.id] = event.target.checked;

		if (!user.enabled) user.with_access = false;

		if (user.with_access) user.enabled = true;

		this.setState({
			user,
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });

		const { user } = this.state;

		const roles = [];
		roles.push(user.role);
		user.roles = roles;

		const response = await employeesService.add(user);

		if (!response.ok) {
			this.setState({
				submitted: true,
				isLoading: false,
				errors: errorFormating(response),
			});
		} else {
			toastr.success("¡Bien!", "Empleado añadido correctamente");

			this.setState({
				submitted: false,
				isLoading: false,
				errors: {},
				user: {
					name: "",
					surname: "",
					company: authService.currentUser.company.id,
					email: "",
					start_day: today,
					// end_day: "",
					enabled: false,
					with_access: false,
					birthday: "",
					phone: "",
					code: "",
					role: "EMPLOYEE",
					roles: [],
					position: "",
				},
			});
		}
	};

	checkValidField = (name) => {
		return (
			this.state.submitted &&
			this.state.errors[name] !== undefined &&
			this.state.errors[name] !== ""
		);
	};

	render() {
		const { user } = this.state;
		var isActive = user.enabled;
		var hasAccess = user.with_access;

		return (
			<Layout className="page-employee-new">
				<div className="page-container container">
					<div className="heading">
						<h1 className="title">
							<FiArrowLeft
								className="button-icon"
								onClick={this.handleBackClick}
							/>{" "}
							Añadir nuevo empleado
						</h1>
					</div>

					<div className="page-content">
						<form className="form-new" onSubmit={this.handleSubmit}>
							<Row>
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="name">
										<FormLabel>
											Nombre <span className="label-required">*</span>
										</FormLabel>
										<FormControl
											autoFocus
											type="text"
											value={user.name}
											onChange={this.handleChange}
											placeholder="Nombre empleado"
											isInvalid={this.checkValidField("name")}
										/>
										<FormControl.Feedback type="invalid">
											{this.state.errors.name}
										</FormControl.Feedback>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="surname">
										<FormLabel>
											Apellidos <span className="label-required">*</span>
										</FormLabel>
										<FormControl
											type="text"
											value={user.surname}
											onChange={this.handleChange}
											placeholder="Apellidos empleado"
											isInvalid={this.checkValidField("surname")}
										/>
										<FormControl.Feedback type="invalid">
											{this.state.errors.surname}
										</FormControl.Feedback>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="email">
										<FormLabel>
											Email <span className="label-required">*</span>
										</FormLabel>
										<FormControl
											type="email"
											value={user.email}
											onChange={this.handleChange}
											placeholder="nombre@miempresa.com"
											isInvalid={this.checkValidField("email")}
										/>
										<FormControl.Feedback type="invalid">
											{this.state.errors.email}
										</FormControl.Feedback>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="phone">
										<FormLabel>Teléfono</FormLabel>
										<FormControl
											type="text"
											value={user.phone}
											onChange={this.handleChange}
											placeholder="99 123 123 123"
											isInvalid={this.checkValidField("phone")}
										/>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup
										controlId="birthday"
										className={`form-date ${user.birthday === "" && "empty"}`}
									>
										<FormLabel>Fecha nacimiento</FormLabel>
										<FormControl
											type="date"
											value={user.birthday}
											onChange={this.handleChange}
											isInvalid={this.checkValidField("birthday")}
										/>
										<FiCalendar />
										<FormControl.Feedback type="invalid">
											{this.state.errors.birthday}
										</FormControl.Feedback>
									</FormGroup>
								</Col>

								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="role">
										<FormLabel>Rol</FormLabel>
										<FormControl
											onChange={this.handleChange}
											value={user.role}
											as="select"
										>
											<option value="ADMIN">Administrador</option>
											<option value="EMPLOYEE">Empleado</option>
										</FormControl>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="position">
										<FormLabel>Cargo</FormLabel>
										<FormControl
											type="text"
											value={user.position}
											onChange={this.handleChange}
											placeholder="Gerente / Administración / Contable / etc..."
										/>
									</FormGroup>
								</Col>
								<Col sm={6} md={4} lg={3}>
									<FormGroup
										controlId="start_day"
										className={`form-date ${user.start_day === "" && "empty"}`}
									>
										<FormLabel>Fecha contratación</FormLabel>
										<FormControl
											type="date"
											value={user.start_day}
											onChange={this.handleChange}
											isInvalid={this.checkValidField("start_day")}
										/>
										<FiCalendar />
										<FormControl.Feedback type="invalid">
											{this.state.errors.start_day}
										</FormControl.Feedback>
									</FormGroup>
								</Col>
								{/* <Col sm={6} md={4} lg={3}>
									<FormGroup
										controlId="end_day"
										className={`form-date ${user.end_day === "" && "empty"}`}
									>
										<FormLabel>Fecha baja</FormLabel>
										<FormControl
											type="date"
											value={user.end_day}
											onChange={this.handleChange}
											isInvalid={this.checkValidField("end_day")}
										/>
										<FiCalendar />
										<FormControl.Feedback type="invalid">
											{this.state.errors.end_day}
										</FormControl.Feedback>
									</FormGroup>
								</Col> */}
								<Col sm={6} md={4} lg={3}>
									<FormGroup controlId="code">
										<FormLabel>Código</FormLabel>
										<FormControl
											type="text"
											value={user.code}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											placeholder="Código empleado"
											isInvalid={this.checkValidField("code")}
										/>
										<FormControl.Feedback type="invalid">
											{this.state.errors.code}
										</FormControl.Feedback>
									</FormGroup>
								</Col>

								<Col sm={6} md={4} lg={3}>
									<Row>
										<Col>
											<FormGroup controlId="enabled">
												<FormLabel className="inline-block">Activo</FormLabel>
												<Switch
													controlId="enabled"
													isOn={isActive}
													handleToggle={this.handleSwitch}
												/>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup controlId="with_access">
												<FormLabel className="inline-block">
													Acceso a Plain
												</FormLabel>
												<Switch
													controlId="with_access"
													isOn={hasAccess}
													handleToggle={this.handleSwitch}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Col>
							</Row>

							<SubmitButton
								//block
								type="submit"
								isLoading={this.state.isLoading}
								text="Añadir empleado"
								loadingText="Guardando..."
							/>
						</form>
					</div>
				</div>
			</Layout>
		);
	}
}

export default EmployeeNew;
