import React, { Fragment } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiClock, FiTrash2 } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import moment from 'moment';
// import uuidv4 from 'uuid/v4';
import TimeInputPolyfill from "react-time-input-polyfill";
import { isSafari } from 'react-device-detect';
import { plansService, employeesService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as shiftsActions from 'actions/shiftsActions';

class PlanShiftEdit extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		const shift_plan = props.plan.shift_plan;
		const shift = shift_plan.shift;

		if (shift_plan.function) {
			shift.function_id = shift_plan.function.id;
		}

		if (shift_plan.time_intervals && shift_plan.time_intervals.length > 0) {
			shift.time_intervals = shift_plan.time_intervals;
		}

		if (shift_plan.comments) {
			shift.comments = shift_plan.comments;
		}

		var selected_ubications = shift_plan.ubications ? shift_plan.ubications : [];

		this.state = {
			isLoading: false,
			submitted: false,
			employee_functions: [],
			employee_ubications: [],
			errors: {},
			new_interval: {},
			new_interval_errors: {},
			shift,
			temp_ubication: {},
			selected_ubications,
			new_ubication_errors: {},
		};
	}

	componentDidMount() {
		this.getEmployeeFunctions();
		this.getEmployeeUbications();
	}

	getEmployeeFunctions = async () => {
		const { plan } = this.props;
		const employee_id = plan.employee.id;
		const employee_functions = await employeesService.getFunctions(employee_id);

		if (employee_functions.ok) {
			this.setState({
				employee_functions
			});
		}
	}

	getEmployeeUbications = async () => {
		const { plan } = this.props;
		const employee_id = plan.employee.id;
		const employee_ubications = await employeesService.getUbications(employee_id);
		//console.log(employee_ubications);

		if (employee_ubications.ok) {
			this.setState({
				employee_ubications
			});
		}
	}

	handleChange = event => {
		const { shift } = this.state;

		this.setState({
			shift: {
				...shift,
				[event.target.id]: event.target.value
			},
		});
	}

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	prepareTimeIntervalsToSubmit = () => {
		const { shift } = this.state;

		return shift.time_intervals && shift.time_intervals.map((time_interval) => {

			const new_interval = {
				start: time_interval.start,
				end: time_interval.end,
				time_type_id: time_interval.time_type_id,
			}

			if (!isNaN(time_interval.id))
				new_interval.id = time_interval.id;

			if (new_interval.time_type_id === undefined)
				new_interval.time_type_id = time_interval.time_type.id;

			return new_interval
		}
		);
	}

	handleSubmit = async event => {
		event.preventDefault();
		const { shift, selected_ubications } = this.state;
		const { plan } = this.props;

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		// console.log(plan);
		const isOvernight = plan.shift_plan.time_intervals_overnight && plan.shift_plan.time_intervals_overnight.length > 0;
		const prevDay = moment(plan.day, "YYYY-MM-DD").add(-1, 'days').format("YYYY-MM-DD");

		const shift_plan = {
			id: plan.shift_plan.id,
			day: isOvernight ? prevDay : plan.day,
			employee_id: plan.employee.id,
			function_id: shift.function_id,
			comments: shift.comments,
			locked: true,
			shift_id: plan.shift_plan.shift.id,
			time_intervals: this.prepareTimeIntervalsToSubmit()
		}

		// console.log(shift_plan);


		if (selected_ubications.length > 0) {
			const selected_ubications_ids = [];

			selected_ubications.map(ubication => {
				selected_ubications_ids.push(ubication.id);
				return true;
			});

			shift_plan.ubication_ids = selected_ubications_ids;

			// console.log(shift_plan.ubication_ids);
		}

		// put
		response = await plansService.updateAssignment(shift_plan);


		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Cambios guardados correctamente');
				props.getAllAssignments();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}

	handleAddChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleChangeNewInterval = event => {
		const { new_interval } = this.state;

		this.setState({
			new_interval: {
				...new_interval,
				[event.target.id]: event.target.value
			},
		});
	}

	addTimeInterval = () => {
		const { shift, new_interval } = this.state;
		const hourFormat = "hh:mm";
		let isValid = true;
		const new_interval_errors = {};
		const time_type = this.props.select_time_types.find(dt => dt.id === parseInt(new_interval.time_type_id));

		if (time_type === undefined) {
			isValid = false;
			new_interval_errors.time_type_id = false;
		}

		if (!new_interval.start) {
			isValid = false;
			new_interval_errors.start = false;
		}

		if (!new_interval.end) {
			isValid = false;
			new_interval_errors.end = false;
		}

		// if (isValid) {
		// 	const start = moment(new_interval.start, hourFormat);
		// 	const end = moment(new_interval.end, hourFormat);

		// 	if (start.isSameOrAfter(end)) {
		// 		isValid = false;
		// 		new_interval_errors.start = false;
		// 		new_interval_errors.end = false;
		// 	}
		// }

		if (isValid) {
			new_interval.time_type = time_type;
			let time_intervals = [];
			if (shift.time_intervals) {
				time_intervals = shift.time_intervals.concat(new_interval);
				time_intervals = time_intervals.sort(function (a, b) {
					a = moment(a.start, hourFormat);
					b = moment(b.start, hourFormat);
					return a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0;
				});
			}
			else {
				time_intervals.push(new_interval);
			}


			this.setState({
				shift: {
					...shift,
					time_intervals
				},
				new_interval: {
					time_type_id: '',
					start: '',
					end: '',
				},
				new_interval_errors: {},
			});
		}
		else {
			this.setState({
				new_interval_errors,
			});
		}
	}

	removeTimeInterval = (interval) => {
		const { shift } = this.state;

		this.setState({
			shift: {
				...shift,
				time_intervals: shift.time_intervals.filter(item => item.start !== interval.start && item.end !== interval.end)
			}
		});
	}

	handleChangeInterval = (rawValue, element, interval, index) => {
		const { shift } = this.state;
		// const hourFormat = "hh:mm";
		let key = element.id.replace("_" + index, "");
		let value_temp = rawValue.split(":");
		if (rawValue !== "" && value_temp.length == 2) {
			// const start = moment(key === "start" ? rawValue : interval.start, hourFormat);
			// const end = moment(key === "end" ? rawValue : interval.end, hourFormat);
			this.setState({
				shift: {
					...shift,
					time_intervals: shift.time_intervals.map((intr, j) => j === index ? { ...intr, [key]: rawValue } : intr)
				}
			});
		}
	};

	getTimeIntervalsBody = () => this.state.shift.time_intervals.map((interval, i) => {
		const { submitted, errors } = this.state;
		var hasError = false;

		if (submitted && Object.keys(errors).length > 0) {
			// console.log(errors[`time_intervals[${i}].start`]);
			hasError = this.checkValidField(`time_intervals[${i}].start`) || this.checkValidField(`time_intervals[${i}].end`);
		}


		return (
			<Fragment key={i}>
				<tr
					className={`${hasError ? "hasError" : ""} tr-interval`}
				>
					<td rowSpan={`${hasError ? "2" : "1"}`}>{interval.time_type.name}</td>
					<td>
						<span className="time-container">
							<TimeInputPolyfill
								id={`start_${i}`}
								key={`${i}_1`}
								className={`form-time`}
								value={
									interval.start
								}
								onChange={({ value, element }) =>
									this.handleChangeInterval(value, element, interval, i)
								}
								required
							/>
						</span>
					</td>
					<td>
						<span className="time-container">
							<TimeInputPolyfill
								id={`end_${i}`}
								key={`${i}_1`}
								className={`form-time`}
								value={
									interval.end
								}
								onChange={({ value, element }) =>
									this.handleChangeInterval(value, element, interval, i)
								}
								required
							/>
						</span>
					</td>
					<td className="td-actions"><button type="button" className="btn btn-icon" onClick={() => this.removeTimeInterval(interval)}><FiTrash2 /></button></td>
				</tr>
				{hasError &&
					<tr><td className='td-error' colSpan={3}>{errors[`time_intervals[${i}].start`]}</td></tr>}
			</Fragment>

		)
	});

	// ubicaciones empleado

	getSelectedUbications = () => this.state.selected_ubications.map((item) => {
		return (
			<li key={item.id} className="tag-default tag-delete">
				{item.name}
				<button type="button" onClick={() => this.removeUbication(item.id)} className="btn-tag-delete btn-transparent"><FiX /></button>
			</li>
		)
	});

	addUbication = () => {
		let { selected_ubications, temp_ubication, employee_ubications } = this.state;
		let isValid = true;
		let employee_ubication;
		const new_ubication_errors = {};

		if (temp_ubication === undefined) {
			isValid = false;
		}
		else {
			employee_ubication = employee_ubications.find(item => item.ubication.id === parseInt(temp_ubication));

			if (employee_ubication === undefined) {
				isValid = false;
				new_ubication_errors.ubication = false;
			}
		}

		if (isValid) {
			// check if ubication already exists
			const exists = selected_ubications.find(item => item.id === parseInt(temp_ubication));

			if (!exists) {
				selected_ubications = selected_ubications.concat(employee_ubication.ubication);
			}

			this.setState({
				selected_ubications,
				new_ubication_errors: {},
				temp_ubication: {},
			});
		}
		else {
			this.setState({
				new_ubication_errors,
			});
		}
	}
	removeUbication = (idUbication) => {
		this.setState((prevState, props) => {
			return {
				selected_ubications: prevState.selected_ubications.filter(item => item.id !== parseInt(idUbication))
			};
		});
	}


	render() {
		const { shift, employee_functions, employee_ubications, errors, new_interval, new_interval_errors, new_ubication_errors, temp_ubication } = this.state;
		const { plan, select_time_types } = this.props;

		const optionTimeTypeList = select_time_types.length > 0 && select_time_types.map((item, i) => {
			var isOk = false;
			// si es ausencia por horas, solo se muestran tipos de tiempo de no trabajo
			if (shift.allow_hourly_absence_request) {
				if (!item.work_time) {
					isOk = true;
				}
			} else {
				isOk = true;
			}

			if (isOk) {
				return (
					<option key={i} value={item.id}>{item.name}</option>
				)
			}
			else {
				return ''
			}

		}, this);

		const optionFunctionList = employee_functions.length > 0 && employee_functions.map((item, i) => {
			const func = item.function;

			return (
				<option key={i} value={func.id}>{func.name}</option>
			)
		}, this);

		const optionUbicationList = employee_ubications.length > 0 && employee_ubications.map((item, i) => {
			const ubication = item.ubication;

			return (
				<option key={i} value={ubication.id}>{ubication.name}</option>
			)
		}, this);

		const employee = plan.employee.name + " " + plan.employee.surname;
		const day = moment(plan.day).format("DD MMMM YYYY");


		// fix de input time para safari
		const isEmptyStartSafari = isSafari && (new_interval.start === null || new_interval.start === undefined);
		const isEmptyEndSafari = isSafari && (new_interval.end === null || new_interval.end === undefined);
		const new_interval_start = isEmptyStartSafari ? "00:00" : new_interval.start;
		const new_interval_end = isEmptyEndSafari ? "00:00" : new_interval.end;

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-shift-edit">

					<Row className="plan-shift-head">
						<Col sm={6} >
							<strong>Empleado</strong> <br />{employee}
						</Col>
						<Col sm={6} >
							<strong>Fecha</strong> <br />{day}
						</Col>
					</Row>

					{employee_functions.length > 0 &&
						<FormGroup controlId="function_id">
							<FormLabel>Función empleado</FormLabel>
							<FormControl
								placeholder="Seleccionar función"
								isInvalid={this.checkValidField('function_id')}
								onChange={this.handleChange}
								value={shift.function_id}
								as="select"
							>
								<option value="">Selecciona</option>
								{optionFunctionList}
							</FormControl>
						</FormGroup>
					}

					{employee_ubications.length > 0 && (
						<div className="row-ubications">
							<div className="flex-group mb10">
								<FormGroup controlId="temp_ubication">
									<FormLabel>Ubicaciones empleado</FormLabel>
									<FormControl
										type="text"
										value={temp_ubication}
										placeholder="Selecciona ubicación"
										onChange={this.handleAddChange}
										// isInvalid={new_ubication_errors.ubication !== undefined}
										as="select"
									>
										<option value="">Selecciona ubicación</option>
										{optionUbicationList}
									</FormControl>
								</FormGroup>
								<button type="button" onClick={this.addUbication} className="btn btn-primary">Añadir</button>
							</div>
							<ul className="list-tags">
								{this.getSelectedUbications()}
							</ul>
						</div>)
					}

					<FormGroup controlId="comments">
						<FormLabel>Observaciones</FormLabel>
						<FormControl
							placeholder="Observaciones o comentarios"
							isInvalid={this.checkValidField('comments')}
							onChange={this.handleChange}
							value={shift.comments}
							type="textarea"
						/>
						<FormControl.Feedback type="invalid">
							{this.state.errors.comments}
						</FormControl.Feedback>
					</FormGroup>

					<div className="time-intervals">
						<h3 className="subtitle">Periodos de tiempo</h3>

						<div className="row-special-days flex-between">
							<FormGroup controlId="time_type_id">
								<FormLabel>Tipo de tiempo</FormLabel>
								<FormControl
									placeholder="Seleccionar tipo"
									isInvalid={new_interval_errors.time_type_id !== undefined}
									onChange={this.handleChangeNewInterval}
									value={new_interval.time_type_id}
									as="select"
								>
									<option value="">Selecciona</option>
									{optionTimeTypeList}
								</FormControl>
							</FormGroup>

							<FormGroup controlId="start" className="form-group-time">
								<FormLabel>Inicio</FormLabel>
								<FormControl
									type="time"
									value={new_interval_start}
									onChange={this.handleChangeNewInterval}
									className={`form-control ${isEmptyStartSafari ? "is-safari is-empty" : ""}`}
									isInvalid={new_interval_errors.start !== undefined}
								/>
								<FiClock />
							</FormGroup>


							<FormGroup controlId="end" className="form-group-time">
								<FormLabel>Fin</FormLabel>
								<FormControl
									type="time"
									value={new_interval_end}
									onChange={this.handleChangeNewInterval}
									className={`form-control ${isEmptyEndSafari ? "is-safari is-empty" : ""}`}
									isInvalid={new_interval_errors.end !== undefined}
								/>
								<FiClock />
							</FormGroup>

							<button type="button" className="btn btn-primary btn-add" onClick={this.addTimeInterval}>Añadir periodo</button>

						</div>

						<div className="form-error">{errors.time_intervals}</div>

						{shift.time_intervals && shift.time_intervals.length > 0 &&
							(<table className="table table-condensed table-zebra-reverse table-specialdays">
								<thead>
									<tr>
										<th>Tipo</th>
										<th>Inicio</th>
										<th>Fin</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{this.getTimeIntervalsBody()}
								</tbody>
							</table>)
						}
					</div>

					{this.state.errors && this.state.errors.shift_id && (
						<div
							className="alert alert-danger alert-general-error fade show"
							role="alert"
						>
							{this.state.errors.shift_id}
						</div>
					)}

				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Guardar"
						loadingText="Guardando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.calendarsReducer,
		...reducers.timesReducer,
		...reducers.shiftsReducer,
		loading: reducers.shiftsReducer.loading
	}
};

export default connect(mapStateToProps, shiftsActions)(PlanShiftEdit)
