import React from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { FormGroup, FormControl, FormLabel, Row, Col } from "react-bootstrap";
import { FiCalendar, FiX, } from "react-icons/fi";
import moment from 'moment';
import { plansService } from "services";
import { errorFormating } from "utils/utils";
import SubmitButton from "components/SubmitButton";
import * as employeesActions from 'actions/shiftsActions';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // datepicker locale
registerLocale("es", es);

class PlanShiftExchange extends React.Component {

	constructor(props) {
		super(props);
		// console.log(props);

		const plan = props.plan;
		// console.log(plan);

		this.state = {
			isLoading: false,
			submitted: false,
			start: plan.day,
			end: plan.day,
			target_employee_id: -1,
			errors: {},
		};
	}

	componentDidMount() {

	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleDateChange = (id, date) => {
		let key = id;
		let value = date ? moment(date).format("YYYY-MM-DD") : null;

		this.setState({
			[key]: value,
		});
	};

	checkValidField = (name) => {
		return (this.state.submitted && this.state.errors[name] !== undefined && this.state.errors[name] !== '');
	}

	handleSubmit = async event => {
		event.preventDefault();
		const { start, end, target_employee_id } = this.state;
		const { plan } = this.props;

		this.setState({ isLoading: true });
		let response = '';
		const props = this.props;

		const exchange_request = {
			start,
			end,
			requester_employee_id: plan.employee.id,
			target_employee_id,
		}

		// console.log(exchange_request);

		// POST
		response = await plansService.assignmentExchange(exchange_request);

		// console.log(response);

		if (response.ok) {
			this.setState({
				isLoading: false,
				errors: {},
			}, () => {

				toastr.success('¡Bien!', 'Intercambio realizado correctamente');
				props.getAllAssignments();
				props.handleClose();

			});
		}
		else {
			this.setState({
				isLoading: false,
				submitted: true,
				errors: errorFormating(response)
			});
		}
	}


	render() {
		const { errors, target_employee_id, start, end } = this.state;
		const { plan, select_employees } = this.props;

		// console.log(errors);


		const employee = plan.employee.name + " " + plan.employee.surname;
		const day = moment(plan.day).format("DD MMMM YYYY");

		const optionEmployeeList = select_employees.length > 0 && select_employees.map((item, i) => {
			return (
				<option key={i} value={item.id}>{item.name} {item.surname}</option>
			)
		}, this);

		return (

			<form onSubmit={this.handleSubmit}>
				<div className="modal-body plan-shift-edit">

					<div className="plan-shift-head">
						<strong>Empleado inicio</strong> <br />{employee}
					</div>

					<FormGroup controlId="target_employee_id">
						<FormLabel>Empleado destino <span className="label-required">*</span></FormLabel>
						<FormControl
							type="text"
							value={target_employee_id}
							onChange={this.handleChange}
							placeholder="Selecciona empleado"
							as="select"
							isInvalid={this.checkValidField('target_employee_id')}
						>
							<option value="">Selecciona empleado</option>
							{optionEmployeeList}
						</FormControl>

						<FormControl.Feedback type="invalid">
							{errors.target_employee_id}
						</FormControl.Feedback>
					</FormGroup>

					<Row>
						<Col sm={6}>
							<FormGroup controlId="start" className={`form-date ${(start === '' || start === null) && 'empty'}  ${this.checkValidField('start') && "is-invalid"}`}>
								<FormLabel>Fecha inicio <span className="label-required">*</span></FormLabel>

								<DatePicker
									selected={(start === "" || start === null) ? start : moment(start).toDate()}
									onChange={(date) => this.handleDateChange("start", date)}
									isInvalid={this.checkValidField("start")}
									className="form-control"
									dateFormat="dd/MM/yyyy"
									locale='es'
									placeholderText="dd/mm/aaaa"
								/>

								<FiCalendar />
								<FormControl.Feedback type="invalid">
									{errors.start}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup controlId="end" className={`form-date ${(end === '' || end === null) && 'empty'}  ${this.checkValidField('end') && "is-invalid"}`}>
								<FormLabel>Fecha fin <span className="label-required">*</span></FormLabel>

								<DatePicker
									selected={(end === "" || end === null) ? end : moment(end).toDate()}
									onChange={(date) => this.handleDateChange("end", date)}
									isInvalid={this.checkValidField("end")}
									className="form-control"
									dateFormat="dd/MM/yyyy"
									locale='es'
									placeholderText="dd/mm/aaaa"
								/>

								<FiCalendar />
								<FormControl.Feedback type="invalid">
									{errors.end}
								</FormControl.Feedback>
							</FormGroup>
						</Col>
					</Row>

					{this.state.errors && (this.state.errors.requester_employee_id || this.state.errors.general) && (
						<div
							className="alert alert-danger alert-general-error fade show"
							role="alert"
						>
							{this.state.errors.requester_employee_id}
							{this.state.errors.general}
						</div>
					)}
				</div>

				<div className="modal-footer">
					<button type="button" onClick={this.props.handleClose} className="btn btn-outline-primary">Cancelar</button>
					<SubmitButton
						type="submit"
						isLoading={this.state.isLoading}
						text="Intercambiar"
						loadingText="Intercambiando..."
					/>
				</div>
			</form>
		)
	}
}


const mapStateToProps = (reducers) => {
	return {
		...reducers.employeesReducer,
		loading: reducers.shiftsReducer.loading
	}
};

export default connect(mapStateToProps, employeesActions)(PlanShiftExchange)
