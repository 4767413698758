import React from "react";
import { connect } from "react-redux";
import * as companyActions from "actions/companyActions";
import Layout from "components/Layout";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Switch from "components/Switch";
import SubscriptionRequest from "components/Profile/SubscriptionModal";
import { isTrial } from "utils/utils";
import { showModal, hideModal } from "actions/modalActions";


class MySubscription extends React.Component {
	constructor(props) {
		super(props);
		//console.log(props);

		this.state = {
			isLoading: false,
			errors: {},
			user: this.props.user,
			prevUser: this.props.user,
			isYearly: true,
		};

		this.closeModal = this.closeModal.bind(this);
		this.openSubscriptionModal = this.openSubscriptionModal.bind(this);
	}

	async componentDidMount() {
		this.props.getSubscriptionTypes();
		const companyId = this.props.user.company.id;
		await this.props.getCompany(companyId);
		await this.props.getCountries();
		await this.props.getEmployeesCount();
		// await this.props.getPaymentModels();
	}

	closeModal() {
		this.props.hideModal();
	}

	openSubscriptionModal(selectedPlan) {
		const { isYearly } = this.state;

		this.props.showModal(
			{
				open: true,
				title: "Gestionar suscripción",
				style: { width: "650px" },
				content: (
					<SubscriptionRequest
						selected_plan={selectedPlan}
						isYearly={isYearly}
						handleClose={this.closeModal}
					/>
				),
				closeModal: this.closeModal,
			},
			"edit"
		);
	}

	handleSwitch = (event) => {
		this.setState({
			[event.target.id]: event.target.checked,
		});
	};

	getContent() {
		const { user, loading, subscription_types, employees_count } = this.props;
		const { isYearly } = this.state;

		if (loading || subscription_types.length === 0) {
			return <Loading />;
		}

		const subscription_type = user.subscription.subscription_type;
		const planFree = subscription_types.find((plan) => plan.name === "Free");
		const planPro = subscription_types.find((plan) => plan.name === "Pro");

		let usuarios_activos =
			employees_count === 1
				? "Actualmente tienes 1 empleado activo"
				: `Actualmente tienes ${employees_count} empleados activos`;

		let usuarios_contratados =
			user.subscription.max_users === 1
				? "Tienes 1 empleado contratado"
				: `Tienes ${user.subscription.max_users} empleados contratados`;
		return (
			<div className="container container-precios">
				<div className="row-switch row-switch-double">
					<span
						className={`label-mensual label-switch ${!isYearly ? "active" : ""
							}`}
					>
						<span className="hidden-xs">Pago </span>trimestral
					</span>

					<Switch
						controlId="isYearly"
						isOn={isYearly}
						handleToggle={this.handleSwitch}
					/>

					<span
						className={`label-anual label-switch ${isYearly ? "active" : ""}`}
					>
						<span className="hidden-xs">Pago </span>Anual
						<span className="oferta hidden"> (10% descuento)</span>
					</span>
				</div>

				<div className="row row-precios justify-content-center">
					{/* {isTrial() && employees_count <= 5 && ( */}
					{isTrial() && (
						<div className="col-sm-6">
							<article className="precios-item">
								<h2 className="precios-titulo">Trial</h2>

								<div className="precios-precio">
									<span className="precios-valor">0€</span>
									<p className="precios-periodo">por tiempo limitado</p>
								</div>

								<ul className="lista-features mb70">
									<li>Control horario sin geolocalización</li>
									<li>Acceso desde móvil y ordenador</li>
									<li>Gestión de vacaciones y ausencias</li>
									<li>Planificación de turnos básica</li>
									{/* <li>Hasta 5 empleados</li> */}
									<li>Soporte por chat</li>
								</ul>
								<p className="active-users">{usuarios_activos}</p>
								{subscription_type.name != planPro.name ? (
									<button type="button" disabled className="btn btn-white">
										Plan actual
									</button>
								) : (
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => this.openSubscriptionModal(planFree)}
									>
										Seleccionar plan
									</button>
								)}
							</article>
						</div>
					)}
					<div className="col-sm-6">
						<article className="precios-item">
							<h2 className="precios-titulo">Pro</h2>

							<div className="precios-precio">
								<span className="precios-valor">
									{/* {isYearly ? "2,4€" : "3€"} */}
									4€
								</span>
								<p className="precios-periodo">por empleado/mes</p>
							</div>

							<ul className="lista-features">
								<li>Control horario con geolocalización</li>
								<li>Acceso desde móvil y ordenador</li>
								<li>Gestión de vacaciones y ausencias</li>
								<li>Planificación de turnos básica</li>
								<li>Planificación de turnos automática</li>
								<li>Soporte por chat y teléfono</li>
							</ul>

							<p className="active-users">{usuarios_activos}</p>
							{subscription_type.name == planPro.name && (
								<p className="contract-users">{usuarios_contratados}</p>
							)}

							<button
								type="button"
								className="btn btn-primary"
								onClick={() => this.openSubscriptionModal(planPro)}
							>
								{subscription_type.name == planPro.name
									? "Editar plan actual"
									: "Seleccionar plan"}
							</button>
						</article>
					</div>
				</div>
				<p className="precio-sin-iva">El precio no incluye el IVA</p>
			</div >
		);
	}

	render() {
		const pathname = this.props.location.pathname;
		const pageTitle = pathname.includes("contract-plans")
			? "Contrata plain"
			: "Mi suscripción";

		return (
			<>
				<Layout className="page-subscription">
					<h1 className="title">{pageTitle}</h1>

					{this.getContent()}
				</Layout>

				<Modal hideModal={this.props.hideModal} />
			</>
		);
	}
}

const mapStateToProps = (reducers) => {
	return {
		...reducers.companyReducer,
		user: reducers.authReducer.user,
	};
};

const mapDispatchToProps = (dispatch) => ({
	hideModal: () => dispatch(hideModal()),
	showModal: (modalProps, modalType) => {
		dispatch(showModal({ modalProps, modalType }));
	},
	getSubscriptionTypes: () => dispatch(companyActions.getSubscriptionTypes()),
	getCompany: (company_id) => dispatch(companyActions.getCompany(company_id)),
	getCountries: () => dispatch(companyActions.getCountries()),
	getEmployeesCount: () => dispatch(companyActions.getEmployeesCount()),
	// getPaymentModels: () => dispatch(companyActions.getPaymentModels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySubscription);
